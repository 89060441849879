import React from "react";
function About() {
    return (
      <React.Fragment>
        <section
          id="about"
          className="about-us ptb-100 "
        >
          <div className="container">
            <div className="row justify-content-end">
              <div className="col-md-6">
                <div className="about-content-left section-heading">
                  <h2 style={{ color: "#ffffff" }}>
                    Nascemos para transformar suas ideias <br />
                    <span>Em infinitas soluções</span>
                  </h2>

                  <div className="single-feature mb-4">
                    <div className="icon-box-wrap d-flex align-items-center mb-2">
                      <div className="mr-3 icon-box">
                        <img
                          src="img/transform_ideas/increase_your_results.png"
                          alt="icon"
                          className="img-fluid"
                        />
                      </div>
                      <p className="mb-0" style={{ color: "#ffffff" }}>
                        Aumente seus resultados e melhore o desempenho da sua
                        empresa.
                      </p>
                    </div>
                  </div>
                  <div className="single-feature mb-4">
                    <div className="icon-box-wrap mb-2">
                      <div className="mr-3 icon-box">
                        <img
                          src="img/transform_ideas/advance_with_technology.png"
                          alt="icon"
                          className="img-fluid"
                        />
                      </div>
                      <p className="mb-0" style={{ color: "#ffffff" }}>
                        Avance com a tecnologia certa e torne o seu negócio mais
                        ágil.
                      </p>
                    </div>
                    <p></p>
                  </div>
                  <div className="single-feature mb-4">
                    <div className="icon-box-wrap mb-2">
                      <div className="mr-3 icon-box">
                        <img
                          src="img/transform_ideas/update_your_resources.png"
                          alt="icon"
                          className="img-fluid"
                        />
                      </div>
                      <p className="mb-0" style={{ color: "#ffffff" }}>
                        Atualize seus recursos e agregue vantagem competitiva ao
                        seu negócio.
                      </p>
                    </div>
                    <p></p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="feature-contents section-heading">
                  <img alt={"feature-contents"} className="img-style-1 img-w100" src="img/transform_ideas/note.png" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
}
export default About;
