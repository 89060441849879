import React from 'react';
import ReactPixel from 'react-facebook-pixel';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';
import _data from '../../data';

function PollSytem(props) {
  const dataPoll = _data.pollSystem;

  const handleClickContact = () => {
    ReactPixel.track(
      {
        test_event_code: 'BUY_TERM',
      },
      { description: 'Clicou em comprar o link das enquetes' }
    );

    window.open(
      'https://api.whatsapp.com/send?phone=5585999020938&text=Olá, vim pelo site da Infinity Solutions e gostaria de mais informações sobre as enquetes!',
      '_blank'
    );
  };

  return (
    <React.Fragment>
      <div className='overflow-hidden'>
        <section
          id='pricing'
          className={
            'package-section ptb-50 ' +
            (props.bgColor && props.bgColor === 'gray'
              ? 'gray-light-bg'
              : 'background-shape-right')
          }
        >
          <div className='container'>
            <div className='container-ls'>
              <div className='leftSide-ls'>
                <p className='text-Tile-ls'>
                  Nosso sitema permite a coleta de feedbacks do seu público de
                  uma maneira fácil e assertiva, essencial para tomadas de
                  decisão dos seus negócios!
                </p>
                <p className='text-body-ls font-weight-bold'>
                  Podemos usar essa ferramenta em diversas situações, como:
                </p>
                <ul className='ul-stylized text-body-ls'>
                  <li>Pesquisas de opinião;</li>
                  <li>Avaliações de desempenho;</li>
                  <li>Pesquisas de satisfação do cliente;</li>
                  <li>Votações eleitorais, entre outros;</li>
                </ul>
                <br />
                <p className='text-body-ls'>
                  <b className='font-weight-bold'>
                    Como esse sistema funciona?
                  </b>{' '}
                  Através das votações do público alvo por meio das opções
                  apresentadas para uma determinada pergunta ou questão. É uma
                  forma simples e eficaz de coletar opiniões e informações de um
                  grande número de pessoas.
                </p>

                <p className='text-body-ls'>
                  <b className='font-weight-bold'>Como são essas enquetes?</b>{' '}
                  Geralmente consistem em uma pergunta ou declaração e uma série
                  de opções de resposta, que podem ser escolhidas pelos
                  usuários. As enquetes podem ser realizadas em diversos
                  formatos, desde enquetes simples de múltipla escolha até
                  enquetes mais complexas que permitem que os usuários escrevam
                  suas próprias respostas.
                </p>
              </div>
              <div className='rightSide-ls'>
                <div className='mobileScreenSize-ls'>
                  <p className='text-Tile-ls text-center'>
                    Veja alguns exemplos das nossas enquetes
                  </p>
                  <OwlCarousel
                    className='owl-carousel owl-theme'
                    items='1'
                    margin={0}
                    autoplay={true}
                    nav={false}
                    dots={true}
                    loop={true}
                  >
                    {dataPoll.iconCarousel.map((imgLinks, index) => {
                      return (
                        <div
                          className='item'
                          key={index}
                          style={{ width: '100%' }}
                        >
                          <img
                            alt={"icon-carousel"}
                            className='img-circle'
                            src={imgLinks}
                          />
                        </div>
                      );
                    })}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className='row justify-content-center '>
            <div className='col-md-8 '>
              <div className='section-heading text-center mb-5 '>
                {props.showTitle && props.showTitle === true && (
                  <span className='badge badge-primary badge-pill'></span>
                )}
                <br />
                <p className='text-Tile-ls text-center'>
                  Viu como funciona e o quanto é relevante o nosso Sistema? Com
                  ele poderá ser tomada ações a partir dos feedbacks recebidos e
                  extrair inteligência de negócio através do resultado da
                  enquete.
                </p>
                <p className='text-Tile-ls text-center'>
                  Quer entender melhor como a nossa plataforma funciona?{' '}
                  <b
                    className='text-info'
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={handleClickContact}
                  >
                    Contate-nos!
                  </b>
                  <br />
                  Nós da <b style={{ color: '#1a4560' }}>
                    Infinity Solutions
                  </b>{' '}
                  temos prazer em lhe ajudar!
                </p>
              </div>
            </div>
          </div>
          <br />
          <br />
        </section>
      </div>
    </React.Fragment>
  );
}

export default PollSytem;
