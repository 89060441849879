import React from "react";
import FormNewsLetter from "./FormNewsLetter";

function NewsLetter() {

    return (
        <div className='NewsLetter'>

            <div className="txt">
                <h3 className='titleTxt'>Inscrever-se NewsLetter</h3>
                <p className='bodyTxt'>

                    <p>Fique por dentro de conteúdos atualizados! Saiba tudo sobre o mundo da Programação!</p>
                    <p>
                        Newsletter é uma forma de te deixar informado(a) sobre um assunto específico 
                        (no nosso caso, sobre programação) por meio do envio de e-mails. 
                        Ou seja, te deixaremos sempre antenado(a) sobre novidades da área.
                    </p>
                </p>
            </div>

            <div className="FormNewsLetter">
                <FormNewsLetter />
            </div>
        </div>
    );
}

export default NewsLetter;
