import React,{useEffect,useState} from "react";
import _data from "../../data";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.min.css";
import "owl.carousel/dist/assets/owl.theme.default.min.css";
 function ListCustomersCarousel (){
    const [trustedCompany,setTruesedCompany] = useState([]);
    useEffect(()=>{
      setTruesedCompany(_data.trustedCompanyAboutUs)
    },[]);
return(
    <>
    {(trustedCompany.companies || []).length > 0 ? (
        <OwlCarousel
          className="owl-theme clients-carousel dot-indicator"
          items="7"
          autoplay={true}
          nav={false}
          dots
          loop
          margin={10}
          center={true}
          autoplayHoverPause={true}
        >
          {(trustedCompany.companies || []).map(
            (company, index) => {
              return (
                <div className="item single-client" key={index}>
                  <img className="client-img" src={company} alt="client logo" />
                </div>
              );
            }
          )}
        </OwlCarousel>
      ) : null}
      </>
)
}
export default ListCustomersCarousel