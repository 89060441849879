import React from 'react'
import Banner from 'react-js-banner'
import dayjs from 'dayjs'

const BannerCourses = () => {
  const endDate = dayjs('2024/06/04', 'YYYY/MM/DD').endOf('day')

  const banner3Css = {
    color: '#FFF',
    backgroundColor: '#133d59',
    fontSize: 22,
    padding: '20px',
  }

  return (
    <Banner
      transitionAppearTime={2000}
      title={
        <div>
          <span>
            Curso de Programação - Construindo um Portfólio de Sucesso
          </span>
          <br/>
          <span>Inscrições abertas</span>
          <p>Finaliza em {`${endDate.format('DD/MM/YYYY')}`}&nbsp;</p>
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://pay.kiwify.com.br/RhdZc6H'
            className='btn app-store-btn page-scroll'
          >
            Matricule-se
          </a>
        </div>
      }
      css={banner3Css}
    />
  )
}

export default BannerCourses
