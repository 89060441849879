import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.min.css";
import "owl.carousel/dist/assets/owl.theme.default.min.css";
import _data from "../../data";

function LinkSystem(props) {
  const dataLinkS = _data.linksSystem;
  return (
    <React.Fragment>
      <div className="overflow-hidden">
        <section
          id="pricing"
          className={
            "package-section ptb-50 " +
            (props.bgColor && props.bgColor === "gray"
              ? "gray-light-bg"
              : "background-shape-right")
          }
        >
          <div className="container">
            <div className="container-ls">
              <div className="leftSide-ls">
                <p className="text-Tile-ls">
                  Se você está aqui já sabe da importância de adicionar links no
                  instagram, e temos muitas possibidades como:
                </p>
                <ul className="ul-stylized text-body-ls">
                  <li>Site oficial da marca;</li>
                  <li>Atendimento pelo Whatsapp;</li>
                  <li>Página da loja em algum marketplace;</li>
                  <li>Facebook;</li>
                  <li>YouTube;</li>
                  <li>TikTok;</li>
                  <li>Kwai, entre outros;</li>
                </ul>
                <br />
                <p className="text-body-ls">
                  Você já deve saber que esses links podem ficar na Bio do
                  Instagram que é algo de extrema importância pois a partir dela
                  você poderá promover a divugação das plataformas desejadas.
                </p>
                <p className="text-body-ls">
                  Porém até o presente momento o Instagram só permite apenas um
                  Link na Bio do perfil seja para perfis pessoais ou
                  comerciais.&nbsp;
                  <span className="text-danger font-weight-bold">
                    Então é melhor que você o faça valer a pena.
                  </span>
                </p>
                <p className="text-body-ls">
                  Use esse Link para chamar a sua prória página de Links, nela
                  poderá ser colocado quantos links forem desejados!
                </p>
              </div>
              <div className="rightSide-ls">
                <div className="mobileScreenSize-ls">
                  <p className="text-Tile-ls text-center">
                    Veja alguns exemplos das nossas páginas de Links
                  </p>
                  <br />
                  <OwlCarousel
                    className="owl-carousel owl-theme"
                    items="1"
                    margin={0}
                    autoplay={true}
                    nav={false}
                    dots={true}
                    loop={true}
                  >
                    {dataLinkS.iconCarousel.map((imgLinks, index) => {
                      return (
                        <div
                          className="item"
                          key={index}
                          style={{ width: "100%" }}
                        >
                          <img alt={"img-link"} className="img-circle" src={imgLinks} />
                        </div>
                      );
                    })}
                  </OwlCarousel>
                </div>
              </div>
            </div>
            <div className="pb-100"></div>
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="section-heading text-center mb-5">
                  {props.showTitle && props.showTitle === true && (
                    <span className="badge badge-primary badge-pill"></span>
                  )}
                  <p className="text-Tile-ls text-center">
                    Nós da{" "}
                    <b style={{ color: "#1a4560" }}>Infinity Solutions</b>{" "}
                    fazemos essa página de links para você!
                  </p>
                  <h2>Escolha o seu plano</h2>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              {(dataLinkS.packages || []).map((plans, index) => {
                return (
                  <div className="col-lg-4 col-md" key={index}>
                    <div
                      className={
                        "card text-center single-pricing-pack popular-price"
                      }
                    >
                      <div className="pt-4">
                        <h5>{plans.plan}</h5>
                      </div>
                      <div className="pricing-img mt-4">
                        <img
                          src={plans.icon}
                          alt="pricing img"
                          className="img-fluid"
                        />
                      </div>
                      <div className="card-header py-4 border-0 pricing-header">
                        <div className="h1 text-center mb-0">
                          <span className="price font-weight-bolder">
                            {plans.price}
                          </span>
                        </div>
                      </div>
                      <div className="card-body">
                        <ul className="list-unstyled text-sm mb-4 pricing-feature-list">
                          {(plans.features || []).map((feature, index) => {
                            return (
                              <li key={feature + "-" + index}>{feature}</li>
                            );
                          })}
                        </ul>
                        <a
                          href={plans.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={"btn mb-3 solid-btn"}
                        >
                          <span>Adquirir</span>
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="section-heading text-center mb-5 mt-5">
                  <h2>Planos para sua empresa</h2>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              {(dataLinkS.packages_enterprise || []).map((plans, index) => {
                return (
                  <div className="col-lg-4 col-md" key={index}>
                    <div
                      className={
                        "card text-center single-pricing-pack popular-price"
                      }
                    >
                      <div className="pt-4">
                        <h5>{plans.plan}</h5>
                      </div>
                      <div className="pricing-img mt-4">
                        <img
                          src={plans.icon}
                          alt="pricing img"
                          className="img-fluid"
                        />
                      </div>
                      <div className="card-header py-4 border-0 pricing-header">
                        <div className="h1 text-center mb-0">
                          <span className="price font-weight-bolder">
                            {plans.price}
                          </span>
                        </div>
                      </div>
                      <div className="card-body">
                        <ul className="list-unstyled text-sm mb-4 pricing-feature-list">
                          {(plans.features || []).map((feature, index) => {
                            return (
                              <li key={feature + "-" + index}>{feature}</li>
                            );
                          })}
                        </ul>
                        <a
                          href={plans.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={"btn mb-3 solid-btn"}
                        >
                          <span>Adquirir</span>
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <br />
          <br />
        </section>
      </div>
    </React.Fragment>
  );
}

export default LinkSystem;
