import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import _data from "../../data";
import api from "../../services/api";
import { formattedPhoneMask , emailValidation} from "../../utils/validations";

const initialCustomer = {
  name: "",
  email: "",
  phone: "",
  company: "",
  message: "",
};
const initFieldsForm = {
  name: false,
  email: false,
  phone: false,
  company: false,
  message: false,
};
function Contact(props) {
  const [disableContactBtn, setDisableContactBtn] = useState(false);
  const [customer, setCustomer] = useState(initialCustomer);
  const [contact, setContact] = useState({});
  const { name, email, phone, company, message } = customer;
  const [fieldsForm, setFieldsForm] = useState({ ...initFieldsForm });
  const [validEmail, setValidEmail] = useState(false);
  const [msgForm, setMsgForm] = useState("");
  useEffect(()=>{


    },[]);
  const handleFormValueChange = (inputName, event, metodo) => {
    event.target.value = inputName ==="phone" ? formattedPhoneMask(event.target.value):event.target.value
    let params = { ...customer };
    params[inputName] = event.target.value;
    setCustomer({ ...params });
    if (metodo === "blur") {
      let val = event.target.value;
      let params = fieldsForm;
      params[inputName] = val === "" ? true : false;
      setFieldsForm({ ...params });
    }
    if (inputName === "email"){
    setValidEmail(emailValidation(event.target.value));
    }
  };
  const menssageForm = (value) => {
    let msg = value
      ? "Sua mensagem foi enviada!"
      : "Verifique os campos e tente novamente!";
    let msgF = value ? (
      <p className="menssageFormS"> {msg} </p>
    ) : (
      <p className="menssageFormE"> {msg} </p>
    );
    setMsgForm(msgF);
    setTimeout(
      function () {
        msgF = "";
        setMsgForm(msgF);
        setDisableContactBtn(false);
      }.bind(this),
      3000
    );
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    setDisableContactBtn(true);
    let customerArr = { ...customer };
    for (const key in customer) {
      if (customerArr.hasOwnProperty(key)) {
        let paramsV = fieldsForm;
        paramsV[key] = true;
        setFieldsForm({ ...paramsV });
      }
    }
    let emptyValues = Object.values(customer).some((obj) => obj === "");
    setValidEmail(emailValidation(customer.email));
    //
    if (!emptyValues && validEmail) {
      // Chamar API
      api
      .post("proposals",{
        name: customer.name,
        email: customer.email,
        cell: customer.phone,
        company: customer.company,
        msg: customer.message
      })
      .then((response)=> {
        menssageForm(true);
      })
      .catch((err)=>{
        console.error("ops! ocorreu um erro" + err);
        menssageForm(false);
      });
      setCustomer({ ...initialCustomer });
      setFieldsForm({ ...initFieldsForm });
    } else {
      menssageForm(false);
    }
  };
  useEffect(() => {
    setContact(_data.contact);
  }, []);
  return (
    <React.Fragment>
      <section
        id="contact"
        className={
          "contact-us ptb-100 " +
          (props.bgColor && props.bgColor === "white" ? "" : "gray-light-bg")
        }
      >
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="section-heading">
                <h3>{contact.title}</h3>
                <p>{contact.description}</p>
              </div>
              <div className="footer-address">
                <h6>
                  <strong>{contact.office}</strong>
                </h6>
                <p>{contact.address}</p>
                <ul>
                  <li>
                    <span>Whatsapp: <a href="https://api.whatsapp.com/send?phone=5585999020938&text=Ol%C3%A1,%20vim%20pelo%20site%20da%20Infinity%20Solutions%20e%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es!" target="_blank" className="link-number">{contact.phone}</a> </span>
                  </li>
                  <li>
                    <span>
                      Email :{" "}
                      <a href="mailto:hello@yourdomain.com">{contact.email}</a>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-7">
              <form
                method="POST"
                id="contactForm1"
                className="contact-us-form"
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
                noValidate
              >
                <h5>Informações</h5>
                <div className="row">
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <input
                        value={name}
                        onChange={(e) => handleFormValueChange("name", e)}
                        onBlur={(e) => handleFormValueChange("name", e, "blur")}
                        type="text"
                        className={
                          fieldsForm.name && customer.name === ""
                            ? "form-controlError"
                            : "form-control"
                        }
                        name="name"
                        placeholder="Nome"
                        required="required"
                      />
                      {fieldsForm.name && customer.name === "" ? (
                        <span className="emptyText"> Digite o nome </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <input
                        value={email}
                        onChange={(e) => handleFormValueChange("email", e)}
                        onBlur={(e) =>
                          handleFormValueChange("email", e, "blur")
                        }
                        type="email"
                        className={
                          fieldsForm.email && customer.email === ""
                            ? "form-controlError"
                            : !validEmail && customer.email !== ""
                            ? "form-controlError"
                            : "form-control"
                        }
                        name="email"
                        placeholder="Email"
                        required="required"
                      />
                      {fieldsForm.email && customer.email === "" ? (
                        <span className="emptyText"> Digite o e-mail </span>
                      ) : !validEmail && customer.email !== "" ? (
                        <span className="emptyText"> E-mail inválido </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <input
                        value={phone}
                        onChange={(e) => handleFormValueChange("phone", e)}
                        onBlur={(e) =>
                          handleFormValueChange("phone", e, "blur")
                        }
                        type="tel"
                        name="phone"
                        className={
                          fieldsForm.phone && customer.phone === ""
                            ? "form-controlError"
                            : "form-control"
                        }
                        id="phone"
                        placeholder="Telefone"
                        maxLength={15}
                      />
                      {fieldsForm.phone && customer.phone === "" ? (
                        <span className="emptyText"> Digite o telefone </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <input
                        value={company}
                        onBlur={(e) =>
                          handleFormValueChange("company", e, "blur")
                        }
                        onChange={(e) => handleFormValueChange("company", e)}
                        type="text"
                        name="company"
                        size="40"
                        className={
                          fieldsForm.company && customer.company === ""
                            ? "form-controlError"
                            : "form-control"
                        }
                        id="company"
                        placeholder="Empresa"
                      />
                      {fieldsForm.company && customer.company === "" ? (
                        <span className="emptyText"> Digite o empresa </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <textarea
                        style={{resize: 'none'}}
                        onChange={(e) => handleFormValueChange("message", e)}
                        onBlur={(e) =>
                          handleFormValueChange("message", e, "blur")
                        }
                        value={message}
                        name="message"
                        id="message"
                        className={
                          fieldsForm.message && customer.message === ""
                            ? "form-controlErrorM"
                            : "form-control"
                        }
                        rows="7"
                        cols="25"
                        placeholder="Mensagem"
                      ></textarea>
                      {fieldsForm.message && customer.message === "" ? (
                        <span className="emptyText"> Digite uma mensagem </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                {msgForm}
                <div className="row">
                  <div className="col-12">
                    <button
                      type="submit"
                      className="btn solid-btn"
                      id="btnContactUs"
                      disabled={disableContactBtn}
                    >
                      Enviar
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default connect((state) => ({
  state,
}))(Contact);
