import React from "react";
function PrivacyPolicy(props) {
  return (
    <React.Fragment>
          <section  id="privacyPolicy" className="ptb-50">
            <div className="container aling-text-term-privacy">
              <p>
                <span className="span-text">
                  A sua privacidade é importante para nós. É política do
                  Infinity Solutions respeitar a sua privacidade em relação a
                  qualquer informação sua que possamos coletar no site da {" "}
                  <a href="https://infinitysolutionsapp.com/">
                    Infinity Solutions
                  </a>
                  , e outros sites que possuímos e operamos.
                </span>
              </p>  
              <p>
                <span className="span-text">
                  Solicitamos informações pessoais apenas quando realmente
                  precisamos delas para lhe fornecer um serviço. Fazemo-lo por
                  meios justos e legais, com o seu conhecimento e consentimento.
                  Também informamos por que estamos coletando e como será usado.
                </span>
              </p>
              <p>
                <span className="span-text">
                  Apenas retemos as informações coletadas pelo tempo necessário
                  para fornecer o serviço solicitado. Quando armazenamos dados,
                  protegemos dentro de meios comercialmente aceitáveis ​​para
                  evitar perdas e roubos, bem como acesso, divulgação, cópia,
                  uso ou modificação não autorizados.
                </span>
              </p>
              <p>
                <span className="span-text">
                  Não compartilhamos informações de identificação pessoal
                  publicamente ou com terceiros, exceto quando exigido por lei.
                </span>
              </p>
              <p>
                <span className="span-text">
                  O nosso site pode ter links para sites externos que não são
                  operados por nós. Esteja ciente de que não temos controle
                  sobre o conteúdo e práticas desses sites e não podemos aceitar
                  responsabilidade por suas respectivas políticas de privacidade
                </span>
                <span className="span-text">.</span>
              </p>
              <p>
                <span className="span-text">
                  Você é livre para recusar a nossa solicitação de informações
                  pessoais, entendendo que talvez não possamos fornecer alguns
                  dos serviços desejados.
                </span>
              </p>
              <p>
                <span className="span-text">
                  O uso continuado de nosso site será considerado como aceitação
                  de nossas práticas em torno de privacidade e informações
                  pessoais. Se você tiver alguma dúvida sobre como lidamos com
                  dados do usuário e informações pessoais, entre em contacto
                  conosco.
                </span>
              </p>
                <span className="span-text">
                  <ul className="l-term-privacy l-tp-disc">
                    <li className="li-tp-m">
                      <span className="span-text">
                        O serviço Google AdSense que usamos para veicular
                        publicidade usa um cookie DoubleClick para veicular
                        anúncios mais relevantes em toda a Web e limitar o
                        número de vezes que um determinado anúncio é exibido
                        para você.
                      </span>
                    </li>
                    <li className="li-tp-m">
                      <span className="span-text">
                        Para mais informações sobre o Google AdSense, consulte
                        as FAQs oficiais sobre privacidade do Google AdSense.
                      </span>
                    </li>
                    <li className="li-tp-m">
                      <span className="span-text">
                        Utilizamos anúncios para compensar os custos de
                        funcionamento deste site e fornecer financiamento para
                        futuros desenvolvimentos. Os cookies de publicidade
                        comportamental usados ​​por este site foram projetados
                        para garantir que você forneça os anúncios mais
                        relevantes sempre que possível, rastreando anonimamente
                        seus interesses e apresentando coisas semelhantes que
                        possam ser do seu interesse.
                      </span>
                    </li>
                    <li className="li-tp-m">
                      <span className="span-text">
                        Vários parceiros anunciam em nosso nome e os cookies de
                        rastreamento de afiliados simplesmente nos permitem ver
                        se nossos clientes acessaram o site através de um dos
                        sites de nossos parceiros, para que possamos creditá-los
                        adequadamente e, quando aplicável, permitir que nossos
                        parceiros afiliados ofereçam qualquer promoção que pode
                        fornecê-lo para fazer uma compra.
                      </span>
                    </li>
                  </ul>
                </span>
              <h3>
                <span className="span-text-h">Compromisso do Usuário</span>
              </h3>
              <p>
                <span className="span-text">
                  O usuário se compromete a fazer uso adequado dos conteúdos e
                  da informação que a Infinity Solutions oferece no site e com
                  caráter enunciativo, mas não limitativo:
                </span>
              </p>
              <ul className="l-term-privacy l-tp-disc">
                <li className="li-tp-m">
                  <span className="span-text">
                    A) Não se envolver em atividades que sejam ilegais ou
                    contrárias à boa fé a à ordem pública;
                  </span>
                </li>
                <li className="li-tp-m">
                  <span className="span-text">
                    B) Não difundir propaganda ou conteúdo de natureza racista,
                    xenofóbica, apostas ou azar, qualquer tipo de
                    pornografia ilegal, de apologia ao terrorismo ou contra os
                    direitos humanos;
                  </span>
                </li>
                <li className="li-tp-m">
                  <span className="span-text">
                    C) Não causar danos aos sistemas físicos (hardwares) e
                    lógicos (softwares) da Infinity Solutions, de seus
                    fornecedores ou terceiros, para introduzir ou disseminar
                    vírus informáticos ou quaisquer outros sistemas de hardware
                    ou software que sejam capazes de causar danos anteriormente
                    mencionados.
                  </span>
                </li>
              </ul>
              <p>
                <br />
              </p>
              <h5>
                <span className="span-text-h">Mais informações</span>
              </h5>
              <p>
                <span className="span-text">
                  Esperemos que esteja esclarecido e, como mencionado
                  anteriormente, se houver algo que você não tem certeza se
                  precisa ou não, geralmente é mais seguro deixar os cookies
                  ativados, caso interaja com um dos recursos que você usa em
                  nosso site.
                </span>
              </p>
              <p>
                <span className="span-text">
                  Esta política é efetiva a partir de&nbsp;20 March 2023 14:25
                </span>
              </p>
            </div>
          </section>
    </React.Fragment>
  );
}
export default PrivacyPolicy;
