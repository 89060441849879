import React from 'react'
import PollSystem from '../components/PollSystem'
import Header from '../components/Header/header'
import FooterSection from '../components/Footer'
import HeroSection from '../components/HeroSection/HeroSectionDefault'
import GoToTop from '../components/GoToTop'

function PollsSystem() {
  return (
    <React.Fragment>
      <Header showLinks={false}/>
      <div className='main'>
        <HeroSection  bgHero="img/pollSystem/bg-term-2.png"/>
        <PollSystem bgColor='gray'/>
      </div>
      <FooterSection/>
      <GoToTop/>
    </React.Fragment>
  )
}

export default PollsSystem
