import React from 'react'
import LinkSystem from '../components/LinkSystem'
import Header from '../components/Header/header'
import FooterSection from '../components/Footer'
import HeroSection from '../components/HeroSection/HeroSectionDefault'
import GoToTop from '../components/GoToTop'

function LinksSystem() {

  return (
    <React.Fragment>
      <Header showLinks={false} />
      <div className='main'>
        <HeroSection bgHero="img/linkSystem/bg-links-1.png"/>
        <LinkSystem bgColor='gray' />
      </div>
      <FooterSection />
      <GoToTop />
    </React.Fragment>
  )
}

export default LinksSystem
