import React from "react";
import { connect } from "react-redux";
function HeroSection (props) {
  const {title="",bgHero=""} = props;
    return (
      <React.Fragment>
        <section
          className={"hero-section ptb-150" +(bgHero ===""? " background-img":" hero-bg-default")}
          style={{
            backgroundImage: `url(${bgHero})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center bottom",
          }}
        >           
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-md-9 col-lg-7">
                <div className="page-header-content text-white text-center pt-sm-5 pt-md-5 pt-lg-0">
                  <h1 className="text-white mb-0"><span>{title}</span></h1>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
}

export default connect(state => ({
  state,
}))(HeroSection);
