import React from "react";
import PrivacyPolicy from "../components/PrivacyPolicy/privacyPolicy";
import Header from "../components/Header/header";
import FooterSection from "../components/Footer";
import HeroSection from "../components/HeroSection/HeroSectionDefault";
import GoToTop from "../components/GoToTop"
function Privacy_Policy() {
  return (
    <React.Fragment>
      <Header showLinks={false}/>
      <div className="main">  
      <HeroSection title="Política de Privacidade"/> 
      <PrivacyPolicy />
      </div>
      <FooterSection />
      <GoToTop/>
    </React.Fragment>
    );
}
export default Privacy_Policy;
