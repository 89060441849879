import React,{useEffect,useState} from "react";
import _data from "../../data";
import ListCustomersCarousel from "../ListCustomersCarousel"
function TrustedCompany (props) {
  const [trustedCompany,setTruesedCompany] = useState([]);
  useEffect(()=>{
    setTruesedCompany(_data.trustedCompanyAboutUs)
  },[]);
    return (
      <React.Fragment>
        <section
          className={
            "client-section ptb-100 " +
            (props.bgColor && props.bgColor === "gray"
              ? "gray-light-bg"
              : "")
          }
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="section-heading text-center mb-5">
                  <h4>{trustedCompany.title}</h4>
                  <p className="lead">
                    {trustedCompany.description}
                  </p>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-md-12">
              <ListCustomersCarousel/>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    ); 
}
export default TrustedCompany;
