import React from 'react'
import _data from '../../data'
import ReactPixel from 'react-facebook-pixel'

function CoursesInfinity(props) {
  const course = _data.courses

  const onBuy = () => {
    ReactPixel.track(
      {
        test_event_code: 'BUY_COURSES',
      },
      { description: 'Clicou em comprar o curso' },
    )

    window.open('https://pay.kiwify.com.br/RhdZc6H', '_blank')
  }

  return (
    <React.Fragment>
      <div className='overflow-hidden'>
        <section
          id='pricing'
          className={
            'package-section ptb-50 ' +
            (props.bgColor && props.bgColor === 'gray'
              ? 'gray-light-bg'
              : 'background-shape-right')
          }
        >
          <div className='container'>
            <div className='row justify-content-center'>
              <div className='col-md-8'>
                <div className='section-heading text-center mb-5'>
                  {props.showTitle && props.showTitle === true && (
                    <span className='badge badge-primary badge-pill'></span>
                  )}
                  <p
                    className='lead'
                    style={{ fontSize: '19px' }}
                  >
                    O curso presencial de programação oferece aulas imersivas e
                    interativas, acompanhamento com profissionais especializados
                    e acesso a projetos práticos.
                  </p>
                  <h2>Escolha o seu plano</h2>
                </div>
              </div>
            </div>
            <div
              id='sectionModules'
              className='row justify-content-center'
            >
              {(course.packages || []).map((plans, index) => {
                return (
                  <div
                    id={plans.id}
                    className='col-lg-4 col-md'
                    key={index}
                  >
                    <div
                      className={
                        'card text-center single-pricing-pack ' +
                        (plans.isFeatured ? 'popular-price' : '')
                      }
                    >
                      <div className='pt-4'>
                        <h5>{plans.license}</h5>
                      </div>
                      <div className='pricing-img mt-4'>
                        <img
                          src={plans.icon}
                          alt='pricing img'
                          className='img-fluid'
                        />
                      </div>
                      <div className='card-header py-4 border-0 pricing-header'>
                        <div className='h1 text-center mb-0'>
                          <span className='price font-weight-bolder'>
                            {plans.price}
                          </span>
                        </div>
                      </div>
                      <div className='card-body'>
                        <ul className='list-unstyled text-sm mb-4 pricing-feature-list'>
                          {(plans.features || []).map((feature, index) => {
                            return (
                              <li key={feature + '-' + index}>{feature}</li>
                            )
                          })}
                        </ul>
                        <a
                          onClick={onBuy}
                          href='#'
                          className={
                            `btn mb-3 ${plans.isFeatured
                              ? 'solid-btn'
                              : 'btn-a-disabled outline-btn'}`
                          }
                        >
                          {plans.license === 'Módulo 1' ? (
                            <span>Matricule-se</span>
                          ) : (
                            <span>Em breve</span>
                          )}
                        </a>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
            <div
              id='sectionEmenta'
              className='row mt-5'
            >
              {(course.menu || []).map((menu, index) => {
                return (
                  <div
                    id={menu.id}
                    className='col-lg-4'
                    key={index}
                  >
                    <div
                      id={`accordion-${index}`}
                      className='accordion accordion-faq'
                    >
                      <div className='card'>
                        <div
                          className='card-header py-4'
                          id={`heading-1-${index}`}
                          data-toggle='collapse'
                          role='button'
                          data-target={`#collapse-${index}`}
                          aria-expanded='true'
                          aria-controls={`collapse-${index}`}
                        >
                          <h6 className='mb-0'>
                            <span className='ti-folder mr-3'></span>
                            {menu.title}
                          </h6>
                        </div>
                        <div
                          id={`collapse-${index}`}
                          className='collapse show'
                          aria-labelledby={`heading-${index}`}
                          data-parent={`#accordion-${index}`}
                        >
                          <div className='card-body'>
                            {(menu.subjects || []).map((subject, index) => {
                              return (
                                <div key={index}>
                                  <h4 key={subject + '-t-' + index}>
                                    {subject.topic}
                                  </h4>
                                  <ul
                                    key={subject + '-u-' + index}
                                    className='ul-stylized'
                                  >
                                    {(subject.content || []).map(
                                      (contentS, index) => {
                                        return (
                                          <li key={contentS + '-' + index}>
                                            <p>{contentS}</p>
                                          </li>
                                        )
                                      },
                                    )}
                                  </ul>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  )
}

export default CoursesInfinity
