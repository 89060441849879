import React from "react"
import { useState } from "react"
import api from "../../services/api"
import { validateEmail } from "../../utils/EmailValidatioNewsLetter"

function FormNewsLetter() {

    const [nomeNewsLetter, setNomeNewsLetter] = useState()
    const [emailNewsLetter, setEmailNewsLeter] = useState()

    const [emailErr, setEmailErr] = useState(false)

    const [emptyName, setEmptyName] = useState(false)
    const [emptyEmail, setEmptyEmail] = useState(false)

    const [invalidForm, setInvalidForm] = useState(false)
    const [validForm, setValidForm] = useState(false)

    const [submitDisabled, setSubmitDisabled] = useState(false)


    function validEmail() {
        setEmailErr(!validateEmail.test(emailNewsLetter))
    }

    //mensagem de erro campos Nome e Mensagem
    function emptyValueName() {
        setEmptyName(!nomeNewsLetter)  
    }

    function emptyValueEmail() {
        setEmptyEmail(!emailNewsLetter)  
    }

    function verificationForm() {
        if (!nomeNewsLetter || !emailNewsLetter || emailErr) {
            setEmptyName(!nomeNewsLetter)

            if (!emailNewsLetter) {
                setEmptyEmail(true)
            } else {
                setEmptyEmail(false)
                setEmailErr(emailErr)
            }

            setInvalidForm(true)
            const timer = setTimeout(() => setInvalidForm(false), 3000);
            return () => clearTimeout(timer);

        } else {
            setInvalidForm(false)
        }
    }

    function enviarNewsLetter(e) {
        e.preventDefault()
        setSubmitDisabled(true)
        setTimeout(() => setSubmitDisabled(false), 3000)

        if (!invalidForm) {
            api
                .post("proposals", {
                    name: nomeNewsLetter,
                    email: emailNewsLetter
                })
                .then((response) => {
                    setValidForm(true)
                })
                .catch((err) => {
                    console.log("Ops! Ocorreu um erro" + err)
                    setInvalidForm(true)
                });

            setValidForm(true)
            const timer = setTimeout(() => setValidForm(false), 3000);
            return () => clearTimeout(timer);

        } else {
            setValidForm(false)
        }


    }

    return (

        <>
            <form onSubmit={enviarNewsLetter}>

                <div className="form">

                    <h4 className="formTitle">Informações</h4>

                    <input
                        type="text"
                        placeholder="Nome"
                        className={!!emptyName ? "emptyErrorName" : "NomeNewsLetter"}
                        onChange={(e) => setNomeNewsLetter(e.target.value)}
                        onBlur={(e) => emptyValueName(e)} />
                    {nomeNewsLetter === "" || (!nomeNewsLetter && emptyName) ? <span className="msgError" ><br />Digite o nome</span> : ""}
                    <br />

                    <input
                        type="text"
                        placeholder="Email"
                        className={!!emptyEmail ? "emptyErrorEmail" : "EmailNewsLetter"}
                        onChange={(e) => validEmail(setEmailNewsLeter(e.target.value))}
                        onBlur={(e) => emptyValueEmail(e)} />
                    {emailNewsLetter === "" || (!emailNewsLetter && emptyEmail) ? <span className="msgError" ><br />Digite o e-mail</span> : ""}
                    {emailErr && emailNewsLetter ? <span className="msgError" ><br />E-mail inválido </span> : ""}
                    <br />

                    {invalidForm ? <div className="formValidationError">Verifique os campos e tente novamente!</div> : ""}
                    {validForm ? <div className="formValidation">Seus dados foram enviados com sucesso!</div> : ""}
                    <button
                        type="submit"
                        className={submitDisabled ? "btn submitDesabled" : "btn SubmitNewsLetter"}
                        onClick={verificationForm}
                        disabled={submitDisabled} >
                        Inscrever-se
                    </button>

                </div>

            </form>

        </>
    )

}

export default FormNewsLetter

