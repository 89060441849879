import React from "react";
import TermsOfUse from "../components/TermsOfUse/termsOfUse";
import Header from "../components/Header/header";
import FooterSection from "../components/Footer";
import HeroSection from "../components/HeroSection/HeroSectionDefault";
import GoToTop from "../components/GoToTop"
function Terms_Of_Use() {
  return (
        <React.Fragment>
        <Header showLinks={false}/>
        <div className="main">  
        <HeroSection title="Termos e Condições"/> 
        <TermsOfUse />
        </div>
        <FooterSection />
        <GoToTop/>
      </React.Fragment>
  );
}
export default Terms_Of_Use;
