import React from "react";
import {connect} from "react-redux";
import ListsAppsCarousel from "../ListAppsCarousel"

function Screenshots() {

  return (
    <React.Fragment>
      <section
        id="screenshots"
        className="screenshots-section ptb-100 gray-light-bg"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="section-heading text-center">
                <h2>
                  Apps à Sistemas Web
                  <br/> <span>Nossas soluções vem das suas ideias</span>
                </h2>
                <p className="lead"></p>
              </div>
            </div>
          </div>
          <div className="screen-slider-content mt-5"
          >
            <ListsAppsCarousel/>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default connect((state) => ({
  state,
}))(Screenshots);
