import React from "react";
import { Link, useHistory} from "react-router-dom";
const Header = (props) => {
  const { showLinks = true } = props;
  let history = useHistory();
  return (
    <React.Fragment>
      <header className="header">
        <nav
          className={
            "navbar navbar-expand-lg fixed-top " +
            (props.bgColor && props.bgColor === "white"
              ? "custom-nav white-bg"
              : "bg-transparent") + (showLinks? "":" transparent")
          }
        >
          <div className="container">
            <Link className="navbar-brand"  to="/">
              <img
                src={
                  props.bgColor && props.bgColor === "white"
                    ? "img/logo-color-1x.png"
                    : "img/logo-color-3.png"
                }
                width="180"
                alt="logo"
                className="img-fluid"
                style={{ margin: "25px 25px 25px 0px" }}
              />
            </Link>
            {!showLinks ? 
              <button className="btn app-store-btn page-scroll" onClick={()=> {
                history.push('/');
              }}>
                Voltar
              </button>
           :   <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="ti-menu"></span>
            </button> }
            { showLinks ?
              <div
                className="collapse navbar-collapse main-menu"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item ">
                    <a className="nav-link page-scroll" href="<GoToTop/>">
                      Home
                    </a>
                    <div
                      className="dropdown-menu submenu"
                      aria-labelledby="navbarDropdownHome"
                    ></div>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link page-scroll" href="#about">
                      Sobre
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link page-scroll" href="#features">
                      Nosso fluxo
                    </a>
                  </li>
                  {/* <li className="nav-item">
                    <a className="nav-link page-scroll" href="#pricing">
                      Pricing
                    </a>
                    </li> */}
                  <li className="nav-item">
                    <a className="nav-link page-scroll" href="#screenshots">
                      Produtos
                    </a>
                  </li>
                  {/*  <li className="nav-item dropdown">
                      <a className="nav-link page-scroll dropdown-toggle" href="/#" id="navbarDropdownPage" role="button"
                          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          Pages <span className="custom-nav-badge badge badge-danger badge-pill">New</span>
                      </a>
                      <div className="dropdown-menu submenu" aria-labelledby="navbarDropdownPage">
                          <a className="dropdown-item" href="login1">Login Page 1</a>
                          <a className="dropdown-item" href="login2">Login Page 2</a>
                          <a className="dropdown-item" href="signup1">Signup Page 1</a>
                          <a className="dropdown-item" href="signup2">Signup Page 2</a>
                          <a className="dropdown-item" href="resetPassword">Password Reset</a>
                          <a className="dropdown-item" href="changePassword">Change Password</a>
                          <a className="dropdown-item" href="download">Download Page</a>
                          <a className="dropdown-item" href="review">Review Page</a>
                          <a className="dropdown-item" href="faq">FAQ Page</a>
                          <a className="dropdown-item" href="404">404 Page</a>
                          <a className="dropdown-item" href="comingSoon">Coming Soon</a>
                          <a className="dropdown-item" href="thankyou">Thank You</a>
                          <a className="dropdown-item" href="team">Team Page</a>
                          <a className="dropdown-item" href="singleTeam">Team Single</a>
                      </div>
                  </li> */}
                  {/* <li className="nav-item dropdown">
                        <a className="nav-link page-scroll dropdown-toggle" href="/#" id="navbarBlogPage" role="button"
                           data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Blog <span className="custom-nav-badge badge badge-danger badge-pill">New</span>
                        </a>

                        <div className="dropdown-menu submenu" aria-labelledby="navbarBlogPage">
                            <a className="dropdown-item" href="blog">Blog Grid</a>
                            <a className="dropdown-item" href="blogLeft">Blog Left Sidebar</a>
                            <a className="dropdown-item" href="blogRight">Details Right Sidebar</a>
                        </div>
                    </li>*/}
                  {/*
                  <li className="nav-item">
                    <a className="nav-link page-scroll" href="#team">
                      Time
                    </a>
                  </li> */}
                  <li className="nav-item">
                    <Link to="/courses">
                      Cursos
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/linkSystem">
                      Links
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/pollSystem">
                      Enquetes
                    </Link>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link page-scroll" href="#contact">
                      Contato
                    </a>
                  </li>
                </ul>
              </div>
               : null }
          </div>
        </nav>
      </header>
    </React.Fragment>
  );
}

export default Header;
