import React from "react";
function Feature(){
    return (  
      <React.Fragment>
       <section id="features"
         className="feature-section ptb-100 "
        >
          <div className="container">
            <div className="row justify-content-end">
              <div className="col-md-6">
 
                <div className="feature-contents section-heading">
                  <h2  style={{color: '#ffffff'}}>
                    Nossos processos de desevolvimento<br />
                    <span>Nosso fluxo</span>
                  </h2>
                  <p  style={{color: '#ffffff'
                
                }}>
                  Através de um processo profundo de mudanças, 
                  implementamos metodologias ágeis para acelerar as nossas entregas e apresentar valor constantemente.
                  </p>

                  <div className="feature-content-wrap">
                    <ul className="nav nav-tabs feature-tab" data-tabs="tabs">
                      <li className="nav-item">
                        <a
                          className="nav-link active h6"
                          href="#tab6-1"
                          data-toggle="tab"
                        >
                          <span className="ti-palette"></span>
                          Design
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link h6"
                          href="#tab6-2"
                          data-toggle="tab"
                        >
                          <span className="ti-vector"></span>
                          Desenvolvimento
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link h6"
                          href="#tab6-3"
                          data-toggle="tab"
                        >
                          <span className="ti-bar-chart"></span>
                          Marketing
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link h6"
                          href="#tab6-4"
                          data-toggle="tab"
                        >
                          <span className="ti-announcement"></span>
                          Branding
                        </a>
                      </li>
                    </ul>
                    {/*<div className="tab-content feature-tab-content">*/}
                    {/*  <div className="tab-pane active" id="tab6-1">*/}
                    {/*    <ul className="list-unstyled">*/}
                    {/*      <li className="py-2">*/}
                    {/*        <div className="d-flex align-items-center">*/}
                    {/*          <div>*/}
                    {/*            <div className="badge badge-circle badge-primary mr-3">*/}
                    {/*              <span className="ti-check"></span>*/}
                    {/*            </div>*/}
                    {/*          </div>*/}
                    {/*          <div>*/}
                    {/*            <p className="mb-0">Tones of SASS variables</p>*/}
                    {/*          </div>*/}
                    {/*        </div>*/}
                    {/*      </li>*/}
                    {/*      <li className="py-2">*/}
                    {/*        <div className="d-flex align-items-center">*/}
                    {/*          <div>*/}
                    {/*            <div className="badge badge-circle badge-primary mr-3">*/}
                    {/*              <span className="ti-check"></span>*/}
                    {/*            </div>*/}
                    {/*          </div>*/}
                    {/*          <div>*/}
                    {/*            <p className="mb-0">*/}
                    {/*              Create your own skin to match your brand*/}
                    {/*            </p>*/}
                    {/*          </div>*/}
                    {/*        </div>*/}
                    {/*      </li>*/}
                    {/*      <li className="py-2">*/}
                    {/*        <div className="d-flex align-items-center">*/}
                    {/*          <div>*/}
                    {/*            <div className="badge badge-circle badge-primary mr-3">*/}
                    {/*              <span className="ti-check"></span>*/}
                    {/*            </div>*/}
                    {/*          </div>*/}
                    {/*          <div>*/}
                    {/*            <p className="mb-0">*/}
                    {/*              Globally orchestrate tactical channels whereas*/}
                    {/*              bricks*/}
                    {/*            </p>*/}
                    {/*          </div>*/}
                    {/*        </div>*/}
                    {/*      </li>*/}
                    {/*      <li className="py-2">*/}
                    {/*        <div className="d-flex align-items-center">*/}
                    {/*          <div>*/}
                    {/*            <div className="badge badge-circle badge-primary mr-3">*/}
                    {/*              <span className="ti-check"></span>*/}
                    {/*            </div>*/}
                    {/*          </div>*/}
                    {/*          <div>*/}
                    {/*            <p className="mb-0">*/}
                    {/*              Use Gulp to prepare all assets for production*/}
                    {/*            </p>*/}
                    {/*          </div>*/}
                    {/*        </div>*/}
                    {/*      </li>*/}
                    {/*      <li className="py-2">*/}
                    {/*        <div className="d-flex align-items-center">*/}
                    {/*          <div>*/}
                    {/*            <div className="badge badge-circle badge-primary mr-3">*/}
                    {/*              <span className="ti-check"></span>*/}
                    {/*            </div>*/}
                    {/*          </div>*/}
                    {/*          <div>*/}
                    {/*            <p className="mb-0">*/}
                    {/*              Collaboratively predominate vertical*/}
                    {/*              manufactured*/}
                    {/*            </p>*/}
                    {/*          </div>*/}
                    {/*        </div>*/}
                    {/*      </li>*/}
                    {/*    </ul>*/}
                    {/*  </div>*/}
                    {/*  <div className="tab-pane" id="tab6-2">*/}
                    {/*    <div className="single-feature">*/}
                    {/*      <div className="d-flex align-items-center mb-2">*/}
                    {/*        <span className="ti-layers rounded mr-3 icon icon-color-1"></span>*/}
                    {/*        <h5 className="mb-0">Easy to use</h5>*/}
                    {/*      </div>*/}
                    {/*      <p>*/}
                    {/*        Synergistically deliver next-generation*/}
                    {/*        relationships whereas bleeding-edge resources.*/}
                    {/*        Continually pontificate stand-alone benefits*/}
                    {/*        whereas.*/}
                    {/*      </p>*/}
                    {/*    </div>*/}
                    {/*    <div className="single-feature mb-4">*/}
                    {/*      <div className="d-flex align-items-center mb-2">*/}
                    {/*        <span className="ti-alarm-clock rounded mr-3 icon icon-color-2"></span>*/}
                    {/*        <h5 className="mb-0">Increase conversion</h5>*/}
                    {/*      </div>*/}
                    {/*      <p>*/}
                    {/*        Phosfluorescently empower compelling intellectual*/}
                    {/*        capital and revolutionary web services. Compellingly*/}
                    {/*        develop cross-media.*/}
                    {/*      </p>*/}
                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*  <div className="tab-pane" id="tab6-3">*/}
                    {/*    <div className="row">*/}
                    {/*      <div className="col single-feature mb-4">*/}
                    {/*        <div className="d-flex align-items-center mb-2">*/}
                    {/*          <span className="ti-bar-chart rounded mr-3 icon icon-color-2"></span>*/}
                    {/*          <h5 className="mb-0">Increase conversion</h5>*/}
                    {/*        </div>*/}
                    {/*        <p>*/}
                    {/*          Display recent conversions, build credibility and*/}
                    {/*          trust.*/}
                    {/*        </p>*/}
                    {/*      </div>*/}
                    {/*      <div className="col single-feature mb-4">*/}
                    {/*        <div className="d-flex align-items-center mb-2">*/}
                    {/*          <span className="ti-stats-up rounded mr-3 icon icon-color-3"></span>*/}
                    {/*          <h5 className="mb-0">Product analytics</h5>*/}
                    {/*        </div>*/}
                    {/*        <p>*/}
                    {/*          A top promo bar that counts down until a few*/}
                    {/*          discounts.*/}
                    {/*        </p>*/}
                    {/*      </div>*/}
                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*  <div className="tab-pane" id="tab6-4">*/}
                    {/*    <p>*/}
                    {/*      I have, to push possibilities, to show people, this is*/}
                    {/*      the level that things could be at. So when you get*/}
                    {/*      something that has the name Kanye West on it, it’s*/}
                    {/*      supposed to be pushing the furthest possibilities. I*/}
                    {/*      will be the leader of a company that ends up being*/}
                    {/*      worth billions of dollars, because I got the answers.*/}
                    {/*      I understand culture. I am the nucleus.*/}
                    {/*    </p>*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
              <div className="feature-contents section-heading">
                  <img alt={"our_flow"} className="img-style-1 img-w75" src="img/our_flow/tablet.png" />
                </div>
              </div>
            </div>
          </div>
        </section>
        </React.Fragment>
    );
}

export default Feature;
