import React from "react";
import { connect } from "react-redux";
import AboutSection from "../AboutUs/AboutUs4";
import FeatureSection from "../Features/Feature3";
function Video(props) {
    return (
      <React.Fragment>
        <section
          id="download"
          className="video-promo background-img-relayout"
          style={{
            backgroundImage: "url('img/video-bg2.png')",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundSize: "cover",
            justifyContent:"center"
          }}
        >
          <AboutSection />
          <hr style={{ borderColor: "#ffffff" }} />
          <FeatureSection />
          <hr style={{ borderColor: "#ffffff" }} />
          <div className="container2 ptb-100">
              <div className="col-md-6 ">
              
                <div className="video-promo-content mt-4 text-center">
                  {/* <a
                    href="https://www.youtube.com/watch?v=9No-FiEInLA"
                    className="popup-youtube video-play-icon d-inline-block"
                  >
                    <span className="ti-control-play"></span>{" "}
                   </a> */}
                  <h5 className="mt-4 text-white">
                    Auxiliamos sua empresa a maximizar o valor de seus ativos,
                    inovar e a responder em tempo real à demanda de seus
                    clientes.
                  </h5>
                  {props.showDownloadBtn &&
                    props.showDownloadBtn === true && (
                      <div className="download-btn mt-5">
                        <a
                          href="#contact"
                          className="btn app-store-btn page-scroll"
                        >
                          Contactar
                        </a>
                        {/*<a href="/#" className="btn google-play-btn mr-3">
                          <span className="ti-android"></span> Google Play
                        </a>
                          <a href="/#" className="btn app-store-btn">
                          <span className="ti-apple"></span> App Store
                        </a>*/}
                      </div>
                    )}
                </div>
              </div>
          </div>
        </section>
      </React.Fragment>
    );
}
export default connect((state) => ({
  state,
}))(Video);
