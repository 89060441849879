import React, { useEffect } from 'react'
import { createStore } from 'redux'
import { Provider } from 'react-redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import AgencyCoApp from './reducers'
import Routes from './routers'
import ReactPixel from 'react-facebook-pixel'
import { APP_CONFIG } from './AppConfig'

const store = createStore(AgencyCoApp, composeWithDevTools())

function App() {
  useEffect(() => {
    const advancedMatching = {}
    const options = {
      autoConfig: true,
      debug: false,
    }

    console.log('PIXEL', APP_CONFIG.PIXEL_ID)

    ReactPixel.init(APP_CONFIG.PIXEL_ID, advancedMatching, options)
  }, [])

  return (
    <Provider store={store}>
      <Routes />
    </Provider>
  )
}

export default App
