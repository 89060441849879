import React, { useEffect } from 'react'
import CoursesInfinity from '../components/CoursesInfinity'
import Header from '../components/Header/header'
import FooterSection from '../components/Footer'
import HeroSection from '../components/HeroSection/HeroSectionCourses'
import GoToTop from '../components/GoToTop'
import ReactPixel from 'react-facebook-pixel'

function Courses() {
  useEffect(() => {
    ReactPixel.track(
      {
        test_event_code: 'COURSES',
      },
      { description: 'Acessou página de cursos' },
    )
  }, [])

  return (
    <React.Fragment>
      <Header showLinks={false} />
      <div className='main'>
        <HeroSection />
        <CoursesInfinity bgColor='gray' />
      </div>
      <FooterSection />
      <GoToTop />
    </React.Fragment>
  )
}

export default Courses
