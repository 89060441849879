import React, { useEffect } from 'react'
import Header from '../components/Header/header'
import HeroSection from '../components/HeroSection/HeroSection4'
import PromoSection from '../components/PromoSection'
import VideoSection from '../components/Video'
import Screenshots from '../components/Screenshots'
import ContactSection from '../components/Contact'
import FooterSection from '../components/Footer'
import GoToTop from '../components/GoToTop'
import ReactPixel from 'react-facebook-pixel'
import BannerCourses from '../components/BannerCourses'
import BannerCookie from '../components/Cooks'
import NewsLetter from '../components/NewsLetter'

function Theme() {
  useEffect(() => {
    ReactPixel.pageView()
  }, [])

  return (
    <React.Fragment>
      <Header />
      <div className='main'>
        <HeroSection />
        <BannerCourses />
        <PromoSection />
        {/*<AboutSection />*/}
        {/*<FeatureSection />*/}
        <VideoSection showDownloadBtn={true} />
        {/*<PricingSection hideFaq={true} faq="four" bgColor="gray" />*/}
        {/*<Testimonial/>*/}
        <Screenshots />
        {/*<TeamMember />*/}
        <ContactSection />
        {/*<TrustedCompany />*/}
      </div>
      <BannerCookie />
      <NewsLetter/>
      {/*<SubscribeSection/>*/}
      <FooterSection />
      <GoToTop />
    </React.Fragment>
  )
}

export default Theme
