import React from "react";
import { connect } from "react-redux";
function HeroSection () {
    return (
      <React.Fragment>
        <section className="bg-hero-section-courses">
        <div
          className="hero-section ptb-150 hero-bg-course" 
           style={{
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center top",
          }}
        > 
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-md-9 col-lg-7">
                <div className="page-header-content text-white text-center pt-sm-5 pt-md-5 pt-lg-0">
                </div>
              </div>
            </div>
          </div>
        </div>
        </section>
      </React.Fragment>
    );
}

export default connect(state => ({
  state,
}))(HeroSection);