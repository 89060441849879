import React,{useState } from "react";
import { COOKIE_ACEEPTED } from "../../constants/types";



function BannerCookie() {
    
    const [cookiesAccepted, setCookiesAccepted] = useState(
      localStorage.getItem(COOKIE_ACEEPTED) === "true"
    );
    
  
    const CookiesAccepted = () => {
      localStorage.setItem( COOKIE_ACEEPTED , "true");
      setCookiesAccepted(true);
    };

   
  return (
    <div className="App">
      {!cookiesAccepted && (
        <div className="cookie-banner">
          <div className="text-cookie">
            <header className="App-header">
              <h5 className="text">Cookies e Política de Privacidade</h5>
            </header>
            <p>
              Nós usamos cookies no nosso site para melhorar sua experiência de
              navegação ao lembrar suas preferências. Clicando em "Aceitar
              Cookies", você concorda com o uso de todos os cookies e com os{" "}
              <a className="gray-link" href="/terms_of_use">
                Termos de Uso
              </a>{" "}
              e{" "}
              <a className="gray-link" href="/privacy_policy">
                Política de Privacidade
              </a>{" "}
              do site.
            </p>
            <button className="btn-cooks" onClick={CookiesAccepted}>
              Aceitar Cookies
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default BannerCookie;
