import React from 'react'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.min.css'
import 'owl.carousel/dist/assets/owl.theme.default.min.css'
function ListsAppsCarousel() {
  return (
    <>
      <OwlCarousel
        className='screen-carousel owl-theme dot-indicator'
        items='4'
        autoplay={true}
        nav={false}
        dots
        loop
        margin={10}
        center={true}
      >
        <div className='item'>
          <img
            src='img/tela-in-ticket.png'
            className='img-fluid'
            alt='screenshots'
          />
        </div>
        <div className='item'>
          <img
            src='img/tela-ibeauty.png'
            className='img-fluid'
            alt='screenshots'
          />
        </div>
        <div className='item'>
          <img
            src='img/app2.png'
            className='img-fluid'
            alt='screenshots'
          />
        </div>
        <div className='item'>
          <img
            src='img/app3.png'
            className='img-fluid'
            alt='screenshots'
          />
        </div>
        <div className='item'>
          <img
            src='img/app4.png'
            className='img-fluid'
            alt='screenshots'
          />
        </div>
        <div className='item'>
          <img
            src='img/tela-links.png'
            className='img-fluid'
            alt='screenshots'
          />
        </div>
        <div className='item'>
          <img
            src='img/tela-term.png'
            className='img-fluid'
            alt='screenshots'
          />
        </div>
      </OwlCarousel>
    </>
  )
}
export default ListsAppsCarousel
