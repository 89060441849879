import React from "react";
function TermsOfUse() {
  return (
    <section  id="privacyPolicy" className="ptb-50">
    <div className="container aling-text-term-privacy">
      <h3>
        <span className="span-text-h">1. Termos</span>
      </h3>
      <p>
        <span className="span-text">
          Ao acessar ao site da {" "}
          <a href="https://infinitysolutionsapp.com/">Infinity Solutions</a>,
          concorda em cumprir estes termos de serviço, todas as leis e
          regulamentos aplicáveis ​​e concorda que é responsável pelo
          cumprimento de todas as leis locais aplicáveis. Se você não concordar
          com algum desses termos, está proibido de usar ou acessar este site.
          Os materiais contidos neste site são protegidos pelas leis de direitos
          autorais e marcas comerciais aplicáveis.
        </span>
      </p>
      <h3>
        <span className="span-text-h">2. Uso de Licença</span>
      </h3>
      <p>
        <span className="span-text">
          É concedida permissão para baixar temporariamente uma cópia dos
          materiais (informações ou software) no site Infinity Solutions,
          apenas para visualização transitória pessoal e não comercial. Esta é a
          concessão de uma licença, não uma transferência de título e, sob esta
          licença, você não pode:&nbsp;
        </span>
      </p>
      <ol className="l-term-privacy l-tp-contagem">
        <li className="li-tp-m">
          <span className="span-text">
            Modificar ou copiar os materiais;&nbsp;
          </span>
        </li>
        <li className="li-tp-m">
          <span className="span-text">
            Usar os materiais para qualquer finalidade comercial ou para
            exibição pública (comercial ou não comercial);&nbsp;
          </span>
        </li>
        <li className="li-tp-m">
          <span className="span-text">
            Tentar descompilar ou fazer engenharia reversa de qualquer software
            contido no site da Infinity Solutions;&nbsp;
          </span>
        </li>
        <li className="li-tp-m">
          <span className="span-text">
            Remover quaisquer direitos autorais ou outras notações de
            propriedade dos materiais;&nbsp;
          </span>
        </li>
        <li className="li-tp-m">
          <span className="span-text">
            Transferir os materiais para outra pessoa ou 'espelhe' os materiais
            em qualquer outro servidor.
          </span>
        </li>
      </ol>
      <p>
        <span className="span-text">
          Esta licença será automaticamente rescindida se você violar alguma
          dessas restrições e poderá ser rescindida por Infinity Solutions a
          qualquer momento. Ao encerrar a visualização desses materiais ou após
          o término desta licença, você deve apagar todos os materiais baixados
          em sua posse, seja em formato eletrónico ou impresso.
        </span>
      </p>
      <h3>
        <span className="span-text-h">3. Isenção de responsabilidade</span>
      </h3>
      <ol className="l-term-privacy l-tp-contagem">
        <li className="li-tp-m">
          <span className="span-text">
            Os materiais no site da Infinity Solutions são fornecidos 'como
            estão'. A Infinity Solutions não oferece garantias, expressas ou
            implícitas, e, por este meio, isenta e nega todas as outras
            garantias, incluindo, sem limitação, garantias implícitas ou
            condições de comercialização, adequação a um fim específico ou não
            violação de propriedade intelectual ou outra violação de direitos.
          </span>
        </li>
        <li className="li-tp-m">
          <span className="span-text">
            Além disso, a Infinity Solutions não garante ou faz qualquer
            representação relativa à precisão, aos resultados prováveis ​​ou à
            confiabilidade do uso dos materiais em seu site ou de outra forma
            relacionado a esses materiais ou em sites vinculados a este site.
          </span>
        </li>
      </ol>
      <h3>
        <span className="span-text-h">4. Limitações</span>
      </h3>
      <p>
        <span className="span-text">
          Em nenhum caso a Infinity Solutions ou seus fornecedores serão
          responsáveis ​​por quaisquer danos (incluindo, sem limitação, danos
          por perda de dados ou lucro ou devido a interrupção dos negócios)
          decorrentes do uso ou da incapacidade de usar os materiais em Infinity
          Solutions, mesmo que a Infinity Solutions ou um representante autorizado
          da Infinity Solutions tenha sido notificado oralmente ou por escrito
          da possibilidade de tais danos. Como algumas jurisdições não permitem
          limitações em garantias implícitas, ou limitações de responsabilidade
          por danos consequentes ou incidentais, essas limitações podem não se
          aplicar a você.
        </span>
      </p>
      <h3>
        <span className="span-text-h">5. Precisão dos materiais</span>
      </h3>
      <p>
        <span className="span-text">
          Os materiais exibidos no site da Infinity Solutions podem incluir
          erros técnicos, tipográficos ou fotográficos. A Infinity Solutions não
          garante que qualquer material em seu site seja preciso, completo ou
          atual. A Infinity Solutions pode fazer alterações nos materiais contidos
          em seu site a qualquer momento, sem aviso prévio. No entanto, a Infinity
          Solutions não se compromete a atualizar os materiais.
        </span>
      </p>
      <h4>
        <span className="span-text-h">6. Links</span>
      </h4>
      <p>
        <span className="span-text">
          A Infinity Solutions não analisou todos os sites vinculados ao seu
          site e não é responsável pelo conteúdo de nenhum site vinculado. A
          inclusão de qualquer link não implica endosso da Infinity Solutions
          por qualquer terceiro. O uso de qualquer site vinculado é por conta
          e risco do usuário.
        </span>
      </p>
      <p>
        <br />
      </p>
      <h5>
        <span className="span-text-h">Modificações</span>
      </h5>
      <p>
        <span className="span-text">
          A Infinity Solutions pode revisar estes termos de serviço do site a
          qualquer momento, sem aviso prévio. Ao usar este site, você concorda
          em ficar vinculado à versão atual desses termos de serviço.
        </span>
      </p>
      <h5>
        <span className="span-text-h">Lei aplicável</span>
      </h5>
      <p>
        <span className="span-text">
          Estes termos e condições são regidos e interpretados de acordo com as
          leis da Infinity Solutions e você se submete irrevogavelmente à
          jurisdição exclusiva dos tribunais naquele estado ou localidade.
        </span>
      </p>
    </div>
    </section>
  );
}
export default TermsOfUse;
